<script lang="ts">
	import doorClosingMechanism from "../assets/images/door-closing-mechanism.png?webp&imagetools";
	import doorClosingMechanismOverlay from "../assets/images/door-closing-mechanism-overlay.png?webp&imagetools";
	import liftingMechanism from "../assets/images/lifting-mechanism.png?webp&imagetools";
	import heating from "../assets/images/heating.png?webp&imagetools";
	import heatingOverlay from "../assets/images/heating-overlay.png?webp&imagetools";
	import productionLines from "../assets/images/production-lines.png?webp&imagetools";
	import foundry from "../assets/images/foundry.png?webp&imagetools";
	import tools from "../assets/images/tools.png?webp&imagetools";
	import LinkCard from "./LinkCard.svelte";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./AreasOfOperation.translations.json";
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";

	const translate = getTranslate(translations);

	const areasOfOperation = [
		{
			title: translate("doorClosingMechanism"),
			sectionName: "Ipsum dolor",
			link: "#",
			image: doorClosingMechanism,
			backgroundImage: doorClosingMechanismOverlay,
		},
		{
			title: translate("liftingMechanism"),
			sectionName: "Ipsum dolor",
			link: "#",
			backgroundImage: liftingMechanism,
		},
		{
			title: translate("heating"),
			sectionName: "Ipsum dolor",
			image: heating,
			link: "#",
			backgroundImage: heatingOverlay,
		},
		{
			title: translate("productionLines"),
			sectionName: "Ipsum dolor",
			link: "#",
			backgroundImage: productionLines,
		},
		{
			title: translate("foundry"),
			sectionName: translate("foundrySection"),
			link: "#",
			backgroundImage: foundry,
		},
		{
			title: translate("toolRoom"),
			sectionName: translate("toolRoomSection"),
			link: "#",
			backgroundImage: tools,
		},
	];
</script>

<div class="container pt-[18.75rem]">
	<h2 use:flyInOnScroll class="mb-[6.25rem] ml-5 text-xl font-semibold md:ml-[7.938rem] md:text-2xl lg:text-4xl">
		{translate("areaOfOperation")}
	</h2>
	<div
		class="grid grid-cols-1 place-items-center justify-center gap-5 px-5 sm:px-8 md:grid-cols-2 md:px-[4.375rem] xl:grid-cols-3"
	>
		{#each areasOfOperation as area, index}
			<LinkCard
				{index}
				title={area.title}
				sectionName={area.sectionName}
				link={area.link}
				image={area.image}
				backgroundImage={area.backgroundImage}
				backgroundImageOverlay
			/>
		{/each}
	</div>
</div>
