
				import Component from "/src/templates/Homepage.svelte";
				//window.onload = () => {
					const props = JSON.parse(window.document.querySelector("#props").innerText);
					const globalSettings = JSON.parse(window.document.querySelector("#global-settings").innerText);
					const context = new Map();
					context.set("globalSettings", globalSettings);
					context.set("breadcrumbs", props.breadcrumbs);
					context.set("microsoftClaritySetting", props.microsoftClaritySetting);
					context.set("pageSlug", props.pageSlug);
					context.set("menu", props.menu);
					context.set("title", props.title);
					context.set("description", props.description);
					context.set("keywords", props.keywords);
					context.set("tenant", props.tenant);
					context.set("productGroups", props.productGroups);
					void new Component({ target: document.body, hydrate: true, props, context });
				//};
			