<script lang="ts">
	import InfoBanner from "../InfoBanner.svelte";
	import { getTranslate } from "../../utils/getTranslate";
	import translations from "./RdBanner.translations.json";
	import { rndPathMap } from "../../../../core/schema/paths/rndPathMap";
	import { getTenant } from "../../contexts/tenantContextKey";
	import { getPathInLocale } from "../../../../core/schema/paths/getPathInLocale.js";
	import rndHeader from "../../assets/videos/brano-research-development-video.mp4";
	import rndHeaderPoster from "../../assets/images/brano-research-development-header.jpg?webp&w=1920&imagetools";
	import rndHeaderMobile from "../../assets/videos/brano-research-development-header-mobile.mp4";

	const { locale } = getTenant();

	const translate = getTranslate(translations, true);
</script>

<InfoBanner
	title={translate("title")}
	video={rndHeader}
	videoMobile={rndHeaderMobile}
	videoPoster={rndHeaderPoster}
	buttonText={translate("buttonText")}
	link="/{getPathInLocale(locale, rndPathMap)}"
>
	<div class="flex flex-col gap-2 font-bold leading-[2.188rem] md:gap-5 md:leading-normal">
		<p class="md:text-2md mb-0 text-[1.125rem]">{translate("research")}</p>
		<p class="md:text-2md mb-0 text-[1.125rem]">{translate("engineering")}</p>
		<p class="md:text-2md mb-0 text-[1.125rem]">{translate("construction")}</p>
		<p class="md:text-2md mb-0 text-[1.125rem]">{translate("productionLines")}</p>
		<p class="md:text-2md text-[1.125rem] font-light">
			{translate("thanksTo")}
		</p>
	</div>
</InfoBanner>
