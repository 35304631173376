<script lang="ts">
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";
	import StaticPicture from "../Picture/StaticPicture.svelte";

	export let image: string;
	export let perex: string;
	export let title: string;
</script>

<div
	use:flyInOnScroll
	class="group relative flex h-[17.188rem] w-full max-w-[36.25rem] flex-col justify-between overflow-hidden px-10 py-5 md:h-[25.313rem] md:px-[3.75rem] xl:py-10"
>
	<StaticPicture
		{image}
		alt={title}
		width={580}
		height={340}
		imgClass="w-full h-full object-cover"
		class="absolute inset-0 -z-10 opacity-80 transition-all duration-300 group-hover:opacity-80 lg:opacity-0 [&_*]:h-full"
	/>
	<div
		class="absolute inset-0 -z-10 bg-black opacity-40 transition-all duration-500 group-hover:opacity-40 lg:opacity-0"
	></div>
	<p
		class="md:text-2md max-w-[20rem] text-[1.125rem] font-light text-white transition-all duration-500 group-hover:text-white lg:text-black"
	>
		{perex}
	</p>
	<p
		class="md:text-2lg mb-0 text-[1.75rem] font-bold text-white transition-all duration-500 group-hover:text-white lg:text-black"
	>
		{title}
	</p>
</div>
