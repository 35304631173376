<script lang="ts">
	import jobAssurance from "../../assets/images/job-assurance.png";
	import uniqueTechnologies from "../../assets/images/unique-technologies.png";
	import interestingJob from "../../assets/images/interesting-job.png";
	import JobQualityCard from "./JobQualityCard.svelte";
	import { getTranslate } from "../../utils/getTranslate.js";
	import translations from "./Jobs.translations.json";
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";
	import Link from "../Link.svelte";
	import JobsVideos from "./JobsVideos.svelte";
	import { careerPathMap } from "../../../../core/schema/paths/careerPathMap";
	import { getTenant } from "../../contexts/tenantContextKey";
	import { getPathInLocale } from "../../../../core/schema/paths/getPathInLocale.js";

	const { locale } = getTenant();
	const translate = getTranslate(translations);
</script>

<div class="container mt-32 flex justify-between px-8 xl:pl-32 xl:pr-[4.375rem]">
	<div class="md:w-full">
		<h2 use:flyInOnScroll class="m-0 max-w-[15rem] text-xl font-semibold md:max-w-full lg:text-2xl xl:text-4xl">
			{translate("joinOurTeam")}
		</h2>
		<p
			use:flyInOnScroll={{ delay: 0.25 }}
			class="lg:text-2lg mt-10 text-lg leading-[2.375rem] md:max-w-[64rem] md:leading-[3.125rem] lg:h-[14.438rem]"
		>
			{translate("weAre")}
		</p>
	</div>
	<Link
		href="/{getPathInLocale(locale, careerPathMap)}"
		asButton
		animated
		containerClass="!w-[27.562rem] min-w-fit"
		variant="secondary"
		class="ml-auto hidden lg:flex"
	>
		<span>{translate("openPositions")}</span>
	</Link>
</div>

<div class="mx-auto max-w-[120rem] px-5 md:px-8 lg:px-[4.438rem]">
	<JobsVideos />

	<div class="container mb-36 mt-12 flex flex-col items-center gap-5 lg:flex-row">
		<JobQualityCard image={jobAssurance} title={translate("jobSecurity")} perex={translate("familly")} />
		<JobQualityCard image={uniqueTechnologies} title={translate("uniqueTechnologies")} perex={translate("hiTech")} />
		<JobQualityCard image={interestingJob} title={translate("interestingJob")} perex={translate("potential")} />
	</div>
</div>
