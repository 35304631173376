<script lang="ts">
	import Layout from "../components/Layout.svelte";
	import type { HomepageProps } from "./HomepageProps";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./Homepage.translations.json";
	import HpHero from "../components/hp/HpHero.svelte";
	import HpCounter from "../components/hp/HpCounter.svelte";
	import HpTiles from "../components/hp/HpTiles.svelte";
	import AnimatedProductPortfolio from "../components/hp/AnimatedProductPortfolio.svelte";
	import JobsBanner from "../components/JobsBanner/JobsBanner.svelte";
	import ProductGroups from "../components/ProductGroups.svelte";
	import AreasOfOperation from "../components/AreasOfOperation.svelte";
	import CanBeInterested from "../components/CanBeInterested.svelte";
	import RdBanner from "../components/hp/RdBanner.svelte";
	import PartnersMap from "../components/PartnersMap.svelte";
	import footerImg from "../assets/images/footerHp.png?webp&w=1920&imagetools";
	import Factory from "../components/hp/Factory.svelte";
	import LookInside from "../components/LookInside.svelte";
	import { getContext } from "svelte";
	import type { ProductGroupWithTextifiedContent } from "../../../core/schema/ProductGroup.js";
	import { productGroupsContextKey } from "../contexts/productGroupsContextKey.js";
	import type { ProductOption } from "../components/hp/ProductOption.js";
	import { Category } from "../../../core/schema/Category.js";
	import aboutTranslations from "../components/About/HpAbout.translations.json";
	import About from "../components/About/About.svelte";

	type $$Props = HomepageProps;

	const translate = getTranslate(translations);
	const { news } = $$restProps as $$Props;

	const productGroups = getContext<ProductGroupWithTextifiedContent[]>(productGroupsContextKey);

	let preloadElement: HTMLElement | null = null;
	let active: ProductOption;

	$: activeProductGroups =
		active === "car"
			? productGroups.filter(
					({ category, urlSlug }) =>
						category === Category.PassengerVehicles || ["uzitkova-vozidla", "commercial-vehicles"].includes(urlSlug),
				)
			: productGroups.filter(({ category }) => category === Category.UtilityVehicles);
</script>

<Layout
	menuTextColor="text-secondary"
	footerImage={footerImg}
	footerHeadline={translate("whereNext")}
	footerSubHeadline={translate("aboutCompany")}
	footerButtonText={translate("buttonText")}
>
	<section>
		<HpHero />
	</section>
	<section bind:this={preloadElement}>
		<About translations={aboutTranslations} />
	</section>
	<section>
		<HpTiles />
	</section>
	<section>
		<HpCounter />
	</section>
	<section>
		<AnimatedProductPortfolio bind:active {preloadElement} />
	</section>
	<section class="container">
		<ProductGroups productGroups={activeProductGroups} showProductsOfGroup={active === "truck"} />
	</section>
	<section>
		<RdBanner />
	</section>
	<section class="container max-w-[120rem]">
		<Factory {preloadElement} />
	</section>
	<section class="pt-56 lg:pt-0">
		<PartnersMap />
	</section>
	<section>
		<AreasOfOperation />
	</section>
	<section>
		<LookInside />
	</section>
	<section>
		<JobsBanner />
	</section>
	<section class="bg-tertiary">
		<CanBeInterested {news} />
	</section>
</Layout>
