<script lang="ts">
	import ring from "../../../../core/assets/icons/ring-with-dot.svg?raw";
	import Icon from "../Icon.svelte";

	export let label: string;
	let containerClass = "";
	export { containerClass as class };
</script>

<div class="flex items-center gap-5 {containerClass}">
	<Icon icon={ring} class="text-primary h-12 w-12" />
	<span class="xl:text-2md text-primary max-w-[10rem] text-base font-bold">{label}</span>
</div>
