<script lang="ts">
	import { onMount } from "svelte";
	import { Locale } from "../../../../core/schema/Locale";
	import { getTenant } from "../../contexts/tenantContextKey";
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";

	const { locale } = getTenant();

	let numbers = [
		{
			target: 85,
			unit: "mil",
			current: 0,
			text: {
				[Locale.cs]: "produktů ročně",
				[Locale.en]: "products per year",
			},
		},
		{
			target: 150,
			unit: "+",
			current: 0,
			text: {
				[Locale.cs]: "kamionů denně",
				[Locale.en]: "trucks per day",
			},
		},
		{
			target: 300,
			unit: "+",
			current: 0,
			text: {
				[Locale.cs]: "registrovaných patentů",
				[Locale.en]: "registered patents",
			},
		},
	];

	let isCounting = false;
	const duration = 1000;

	function startCounting(): void {
		if (isCounting) {
			return;
		}
		isCounting = true;
		const startTime = performance.now();

		function updateNumbers(now: number): void {
			const elapsedTime = now - startTime;

			numbers = numbers.map((number) => {
				if (elapsedTime < duration) {
					return {
						...number,
						current: Math.min(number.target, Math.floor((elapsedTime / duration) * number.target)),
					};
				} else {
					return {
						...number,
						current: number.target,
					};
				}
			});

			if (elapsedTime < duration) {
				requestAnimationFrame(updateNumbers);
			}
		}

		requestAnimationFrame(updateNumbers);
	}

	onMount(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					startCounting();
					observer.disconnect();
				}
			});
		});

		observer.observe(document.getElementById("counter-container") as Element);
	});
</script>

<div
	id="counter-container"
	class="container flex flex-col justify-between gap-[5.625rem] pb-[16.875rem] pt-40 md:flex-row lg:gap-0 lg:pt-8"
>
	{#each numbers as number}
		<div
			use:flyInOnScroll={{ duration: 1.25 }}
			class="flex flex-col items-start justify-center sm:px-6 lg:h-[32.1875rem] lg:w-[32.1875rem] lg:px-0"
		>
			<span class="text-xl font-semibold leading-[1.136] lg:text-4xl lg:leading-[5.25rem]">
				{number.current}
				{number.unit}
			</span>
			<p class="lg:text-2lg m-0 p-0 text-[1.75rem] font-bold leading-[1.2142] lg:leading-[1.3157]">
				{number.text[locale]}
			</p>
		</div>
	{/each}
</div>
