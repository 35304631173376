<script lang="ts">
	import translations from "./HpHero.translations.json";
	import { getTranslate } from "../../utils/getTranslate";
	import HeroBanner from "../HeroBanner.svelte";
	import hpHero from "../../assets/videos/brano-hp-header.mp4";
	import hpHeroPoster from "../../assets/images/brano-hp-header.jpg?webp&w=1920&imagetools";
	import hpHeroMobile from "../../assets/videos/brano-hp-header-mobile.mp4";

	const translate = getTranslate(translations);
</script>

<HeroBanner
	title={translate("headline")}
	subheadline={translate("subHeadline")}
	contentClass="justify-between"
	backgroundVideo={hpHero}
	backgroundVideoMobile={hpHeroMobile}
	videoPoster={hpHeroPoster}
	linkText={translate("showMore")}
/>
